
import React from 'react';
import '../CSS/encart.css';

const EncartBlanc = ({ text1, text2, size }) => (
  <section className="whitePage">
    <div className="bradcumbContent2 ">
      <p>{text1}</p>
      <h2>{text2}</h2>
    </div>
  </section>
);

export default EncartBlanc;
